import * as React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import {
  OverlayTrigger,
  Popover,
  PopoverTitle,
  PopoverContent,
} from "react-bootstrap";
import { BsChevronDown } from "react-icons/bs";
import { HiOutlineInformationCircle, HiOutlineCog } from "react-icons/hi";
import NumberFormat from "react-number-format";
import { tickers } from "config";
import { RawTransactionType } from "helpers/types";
import useNewTransaction from "pages/Transaction/useNewTransaction";
import { routeNames } from "routes";
import Input from "../../../components/Input";
import {
  asciiToHex,
  bnToHexStringPadded,
  getAmountOut,
  getESDTBalance,
  getPairAddress,
  getPairInfo,
  parseTokenDecimals,
} from "../helpers/swap";

const Actions = () => {
  const sendTransaction = Dapp.useSendTransaction();
  const { address } = Dapp.useContext();
  const newTransaction = useNewTransaction();

  const [from, setFrom] = React.useState<number | string>("");
  const [fromTicker, setFromTicker] = React.useState<string>(
    tickers.WEGLD.value,
  );
  const [fromBalance, setFromBalance] = React.useState<number | string>("");
  const [to, setTo] = React.useState<number | string>("");
  const [toTicker, setToTicker] = React.useState<string>(tickers.USDC.value);
  const [toBalance, setToBalance] = React.useState<number | string>(0);
  const [slippage, setSlippage] = React.useState(1);
  const [gasLimit, setGasLimit] = React.useState<number | string>(100000000);
  const [gasPrice, setGasPrice] = React.useState<number | string>(50000000000);

  const [exchangeRate, setExchangeRate] = React.useState<string>("");

  const popover = (
    <Popover
      id="popover-basic"
      className="p-3 rounded-xl max-w-screen-sm bg-input shadow"
    >
      <PopoverTitle className="bg-input text-xl text-center">
        Transaction Settings
      </PopoverTitle>
      <PopoverContent>
        <div className="flex justify-center items-center mx-3">
          <div className="flex flex-col space-y-2">
            <div className="flex text-lg items-center">
              <p className="w-20 mr-2">Slippage</p>
              <NumberFormat
                inputMode="numeric"
                allowNegative={false}
                allowLeadingZeros={true}
                fixedDecimalScale={false}
                displayType="input"
                thousandSeparator={true}
                suffix="%"
                className="border focus:outline-none rounded-xl p-2 text-center"
                value={slippage}
                onValueChange={(values) => {
                  setSlippage(parseInt(values.value));
                }}
                placeholder="Slippage"
              />
              {/* <input
                type="text"
                inputMode="numeric"
                className="border focus:outline-none rounded px-2"
                value={slippage}
                onChange={(e) => setSlippage(parseInt(e.target.value))}
                placeholder="To"
              /> */}
            </div>
            <div className="flex text-lg items-center">
              <p className="w-20 mr-2">Gas Limit</p>
              <NumberFormat
                inputMode="numeric"
                allowNegative={false}
                allowLeadingZeros={true}
                fixedDecimalScale={false}
                displayType="input"
                thousandSeparator={true}
                className="border focus:outline-none rounded-xl p-2 text-center"
                value={gasLimit}
                onValueChange={(values) => {
                  setGasLimit(values.value);
                }}
                placeholder="Gas Limit"
              />
              {/* <input
                type="text"
                className="border focus:outline-none rounded px-2"
                value={gasLimit}
                onChange={(e) => setGasLimit(e.target.value)}
                placeholder="To"
              /> */}
            </div>
            <div className="flex text-lg items-center">
              <p className="w-20 mr-2">Gas Price</p>
              <NumberFormat
                inputMode="numeric"
                allowNegative={false}
                allowLeadingZeros={true}
                fixedDecimalScale={false}
                displayType="input"
                thousandSeparator={true}
                className="border focus:outline-none rounded-xl p-2 text-center"
                value={gasPrice}
                onValueChange={(values) => {
                  setGasPrice(values.value);
                }}
                placeholder="Gas Price"
              />
              {/* <input
                type="text"
                className="border focus:outline-none rounded px-2"
                value={gasPrice}
                onChange={(e) => setGasPrice(e.target.value)}
                placeholder="To"
              /> */}
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );

  React.useEffect(() => {
    const main = async () => {
      setExchangeRate(await getPairInfo(fromTicker, toTicker));
      setFromBalance(await getESDTBalance(address, fromTicker));
      setToBalance(await getESDTBalance(address, toTicker));
      // console.log(erdGas);
    };
    main();
  }, [fromTicker, toTicker]);

  React.useEffect(() => {
    changeTo(from.toString());
  }, [fromTicker, toTicker]);

  const changeTo = async (input: string) => {
    if (input) {
      try {
        if (input.charAt(input.length - 1) === ".") {
          input = input.slice(0, input.length - 1);
        }

        const amount = parseTokenDecimals(fromTicker, input).toString();

        const amountOut = await getAmountOut(amount, fromTicker, toTicker);

        setTo(amountOut.toFixed(4));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const swap = (e: React.MouseEvent) => {
    try {
      const pairAddress = getPairAddress(fromTicker, toTicker);
      const amountOut = (parseFloat(to.toString()) / 100) * (100 - slippage);

      const fromHex = parseTokenDecimals(fromTicker, from.toString());

      const fromHexStringPadded = bnToHexStringPadded(fromHex);
      const toHexStringPadded = bnToHexStringPadded(
        parseTokenDecimals(toTicker, amountOut.toFixed(4)),
      );

      console.log(
        `ESDTTransfer@${asciiToHex(
          fromTicker,
        )}@${fromHexStringPadded}@73776170546f6b656e734669786564496e707574@${asciiToHex(
          toTicker,
        )}@${toHexStringPadded}`,
      );

      const swapTransaction: RawTransactionType = {
        receiver: pairAddress,
        data: `ESDTTransfer@${asciiToHex(
          fromTicker,
        )}@${fromHexStringPadded}@73776170546f6b656e734669786564496e707574@${asciiToHex(
          toTicker,
        )}@${toHexStringPadded}`,
        value: "0",
        gasLimit: parseInt(gasLimit.toString()),
        gasPrice: parseInt(gasPrice.toString()),
      };
      e.preventDefault();
      sendTransaction({
        transaction: newTransaction(swapTransaction),
        callbackRoute: routeNames.transaction,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const swapTicker = () => {
    const temp = toTicker;
    setToTicker(fromTicker);
    setFromTicker(temp);
    changeTo(from.toString());
  };

  return (
    <div className="w-full shadow rounded-2xl">
      <div className="flex flex-col bg-white p-4 rounded space-y-2 relative">
        <div className="absolute top-9 right-6">
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="bottom"
            overlay={popover}
          >
            <button>
              <HiOutlineCog size={24} style={{ color: "#6C757A" }} />
            </button>
          </OverlayTrigger>
        </div>
        <Input
          value={fromTicker}
          otherValue={toTicker}
          changeValue={setFromTicker}
          changeOtherValue={setToTicker}
          title="From"
          defaultValue={tickers.WEGLD}
          balance={parseFloat(fromBalance.toString()).toFixed(4)}
          inputValue={from}
          changeInputValue={setFrom}
          changeTo={changeTo}
        />
        <div className="flex justify-center">
          <button
            className="bg-gray-200 hover:bg-blue-500 p-3 rounded-full"
            onClick={swapTicker}
          >
            <BsChevronDown size={14} />
          </button>
        </div>
        <Input
          title="To"
          value={toTicker}
          otherValue={fromTicker}
          changeValue={setToTicker}
          changeOtherValue={setFromTicker}
          defaultValue={tickers.USDC}
          balance={parseFloat(toBalance.toString()).toFixed(4)}
          inputValue={to}
          changeInputValue={setTo}
          changeTo={changeTo}
        />
        <div className="flex justify-center items-center space-x-2 text-lg">
          <HiOutlineInformationCircle />
          <p>{exchangeRate}</p>
        </div>
        {/* <div className="flex justify-center items-center">
          <div className="flex flex-col space-y-2">
            <div className="flex text-lg">
              <p className="w-20 mr-2">Slippage</p>
              <input
                type="text"
                className="border focus:outline-none rounded px-2"
                value={slippage}
                onChange={(e) => setSlippage(parseInt(e.target.value))}
                placeholder="To"
              />
              <p className="-ml-6">%</p>
            </div>
            <div className="flex text-lg">
              <p className="w-20 mr-2">Gas Limit</p>
              <input
                type="text"
                className="border focus:outline-none rounded px-2"
                value={gasLimit}
                onChange={(e) => setGasLimit(e.target.value)}
                placeholder="To"
              />
            </div>
            <div className="flex text-lg">
              <p className="w-20 mr-2">Gas Price</p>
              <input
                type="text"
                className="border focus:outline-none rounded px-2"
                value={gasPrice}
                onChange={(e) => setGasPrice(e.target.value)}
                placeholder="To"
              />
            </div>
          </div>
        </div> */}
        <button
          className="border border-black mt-2 hover:pointer-cursor rounded-xl btn btn-primary text-white p-2 text-xl"
          onClick={swap}
        >
          Swap
        </button>
      </div>
    </div>
  );
};

export default Actions;
