import * as React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { Navbar as BsNavbar } from "react-bootstrap";
import {
  OverlayTrigger,
  Popover,
  PopoverTitle,
  PopoverContent,
} from "react-bootstrap";
import NumberFormat from "react-number-format";
import { Link, useHistory } from "react-router-dom";
import { networkConfig, wrapContractAddress } from "config";
import { RawTransactionType } from "helpers/types";
import { getESDTBalance } from "pages/Swap/helpers/swap";
import useNewTransaction from "pages/Transaction/useNewTransaction";
import { routeNames } from "routes";
import Denominate from "../../../components/Denominate";
import truncateAddress from "../../../utils/truncateAddress";
import { ReactComponent as ElrondLogo } from "./../../../assets/img/elrond.svg";

const Navbar = () => {
  const sendTransaction = Dapp.useSendTransaction();
  const newTransaction = useNewTransaction();
  const { loggedIn } = Dapp.useContext();
  const dappLogout = Dapp.useLogout();
  const history = useHistory();

  const logOut = (e: React.MouseEvent) => {
    e.preventDefault();
    dappLogout({ callbackUrl: `${window.location.origin}/` });
    history.push("/");
  };

  const {
    address,
    account: { balance },
  } = Dapp.useContext();

  const [showWrapInput, setShowWrapInput] = React.useState(false);
  const [amountToWrap, setAmountToWrap] = React.useState<any>(0);
  const [esdtBalance, setESDTBalance] = React.useState<any>([]);

  const wrapEgldTransaction: RawTransactionType = {
    receiver: wrapContractAddress,
    data: "wrapEgld",
    value: amountToWrap.toString(),
    gasLimit: 10000000,
    gasPrice: 10000000000,
  };

  const send = (transaction: RawTransactionType) => (e: React.MouseEvent) => {
    if (amountToWrap === 0) {
      setShowWrapInput(false);
    } else {
      e.preventDefault();
      sendTransaction({
        transaction: newTransaction(transaction),
        callbackRoute: routeNames.transaction,
      });
    }
  };

  React.useEffect(() => {
    const main = async () => {
      const res = await getESDTBalance(address);
      // const res = await getESDTBalance(address, tickers.WEGLD.value);
      setESDTBalance(res);
    };
    main();
  }, [address]);

  const popover = (
    <Popover
      id="popover-basic"
      className="p-3 rounded-xl max-w-screen-sm bg-input shadow"
    >
      <PopoverTitle className="bg-input text-xl text-center">
        Account details
      </PopoverTitle>
      <PopoverContent>
        <div className="flex justify-center items-center mx-3">
          <div className="flex flex-col space-y-2">
            <div className="flex text-lg items-center justify-between gap-4">
              <div className="flex items-center gap-2">
                {showWrapInput ? (
                  <NumberFormat
                    value={amountToWrap}
                    onValueChange={(values: any) =>
                      setAmountToWrap(parseFloat(values.value).toFixed(4))
                    }
                    className="w-24 p-1 border border-input"
                    placeholder="Amount"
                    inputMode="numeric"
                    allowNegative={false}
                    allowLeadingZeros={true}
                    fixedDecimalScale={false}
                    displayType="input"
                    thousandSeparator={true}
                  />
                ) : (
                  <p className="mr-2">Elrond eGold</p>
                )}
                {showWrapInput ? (
                  <button
                    className="btn btn-primary"
                    onClick={send(wrapEgldTransaction)}
                  >
                    Wrap
                  </button>
                ) : (
                  <button
                    className="btn btn-outline-primary p-1"
                    onClick={() => {
                      setShowWrapInput(true);
                    }}
                  >
                    Wrap
                  </button>
                )}
              </div>
              <p className="">
                <Denominate value={balance} dataTestId="balance" />
              </p>
              {/* <input
                type="text"
                inputMode="numeric"
                className="border focus:outline-none rounded px-2"
                value={slippage}
                onChange={(e) => setSlippage(parseInt(e.target.value))}
                placeholder="To"
              /> */}
            </div>
            {esdtBalance
              ? esdtBalance.map((token: any) => (
                  <div
                    className="flex text-lg items-center justify-between gap-4"
                    key={token.ticker}
                  >
                    <p>{token.label}</p>
                    <p>
                      {token.balance} {token.ticker}
                    </p>
                  </div>
                ))
              : null}
            <div className="w-full border-t border-gray-200 mt-4 mb-2"></div>
            <div className="grid grid-cols-2 text-lg items-center justify-between gap-4">
              <a
                target="_blank"
                rel="noreferrer"
                href={networkConfig.explorerAddress + "accounts/" + address}
                className="btn btn-primary text-lg rounded-xl"
              >
                View on Explorer
              </a>
              <a
                href="/"
                onClick={logOut}
                className="btn btn-primary text-lg rounded-xl"
              >
                Disconnect
              </a>
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );

  return (
    <BsNavbar className="bg-white px-4 py-3">
      <div className="container-fluid">
        <Link
          className="d-flex align-items-center navbar-brand mr-0"
          to={loggedIn ? "/swap" : "/"}
        >
          <ElrondLogo className="elrond-logo" />
          <span className="dapp-name text-muted">Exchange</span>
        </Link>

        <div className="d-flex align-items-center">
          {address ? (
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="bottom"
              overlay={popover}
            >
              <button className="btn btn-success">
                {truncateAddress(address)}
              </button>
            </OverlayTrigger>
          ) : null}
        </div>
      </div>
    </BsNavbar>
  );
};

export default Navbar;
