import React from "react";
import { networkConfig } from "config";

const Footer = () => {
  return (
    <footer className="text-center mt-2 mb-3">
      <div>
        <p>Currently on Elrond {networkConfig.name}</p>
      </div>
    </footer>
  );
};

export default Footer;
