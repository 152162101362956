import * as React from "react";
import debounce from "lodash.debounce";
import NumberFormat from "react-number-format";
import Select from "react-select";
import { tickers } from "config";

const Input = ({
  value,
  otherValue,
  changeValue,
  changeOtherValue,
  title,
  defaultValue,
  balance,
  inputValue,
  changeInputValue,
  changeTo,
}: any) => {
  // React.useEffect(() => {}, []);
  const getSelected = (selected: string) => {
    switch (selected) {
      case tickers.USDC.value:
        return tickers.USDC;
      case tickers.WEGLD.value:
        return tickers.WEGLD;
      case tickers.RIDE.value:
        return tickers.RIDE;
      case tickers.MEX.value:
        return tickers.MEX;
      default:
        return tickers.WEGLD;
    }
  };

  const handleText = debounce((text) => {
    if (title === "From") {
      changeTo(text);
    }
  }, 1000);

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      border: "1px solid #E5E7EB",
      borderRadius: "10px",
    }),
  };

  return (
    <div className="">
      <p className="text-left text-xl font-extralight">Swap {title}:</p>
      <div className="flex min-h-20 items-center justify-center border border-input rounded-xl space-x-2 p-3 mt-2 bg-input">
        <NumberFormat
          value={inputValue}
          onValueChange={(values) => {
            console.log(values);
            changeInputValue(values.value);
            handleText(values.value);
          }}
          inputMode="numeric"
          allowNegative={false}
          allowLeadingZeros={true}
          fixedDecimalScale={false}
          displayType="input"
          thousandSeparator={true}
          className="w-full focus:outline-none text-xl bg-input p-2"
          placeholder="Amount"
        />
        <button
          onClick={() => {
            changeInputValue(balance);
            if (title === "From") {
              changeTo(balance);
            }
          }}
          className="text-xs text-blue-800 bg-blue-200 hover:bg-blue-800 hover:text-white px-2 py-1 rounded-2xl"
        >
          MAX
        </button>
        <div className="w-56">
          <Select
            options={Object.values(tickers)}
            value={getSelected(value)}
            onChange={(e) => {
              changeValue(e.value);
              if (otherValue === e.value) {
                changeOtherValue(tickers.WEGLD.value);
              }
              if (
                otherValue === e.value &&
                otherValue === tickers.WEGLD.value
              ) {
                changeOtherValue(tickers.USDC.value);
              }
              if (otherValue !== tickers.WEGLD.value) {
                changeOtherValue(tickers.WEGLD.value);
              }
            }}
            defaultValue={defaultValue}
            isSearchable={false}
            styles={customStyles}
          />
        </div>
      </div>
      <p className="text-right text-gray-500">
        Balance: {balance ? balance : 0}
      </p>
    </div>
  );
};

export default Input;
