import axios from "axios";
import { BigNumber, ethers } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import { pairAddress, tickers } from "config";
import { networkConfig } from "config";

// return ticker decimal base on ticker value, ex: ticker WEGLD-f643d8 return decimal 18
export const getTickerDecimals = (ticker: string) => {
  switch (ticker) {
    case tickers.USDC.value:
      return tickers.USDC.decimals;
    case tickers.WEGLD.value:
      return tickers.WEGLD.decimals;
    case tickers.MEX.value:
      return tickers.MEX.decimals;
    case tickers.RIDE.value:
      return tickers.RIDE.decimals;
    default:
      return 18;
  }
};

// calculate token with decimal, return int number
export const calculateTokenDecimals = (ticker: string, amount: any) => {
  switch (ticker) {
    case tickers.WEGLD.value:
      return parseInt(amount) / 10 ** tickers.WEGLD.decimals;
    case tickers.USDC.value:
      return parseInt(amount) / 10 ** tickers.USDC.decimals;
    case tickers.RIDE.value:
      return parseInt(amount) / 10 ** tickers.RIDE.decimals;
    case tickers.MEX.value:
      return parseInt(amount) / 10 ** tickers.MEX.decimals;
    default:
      return 0;
  }
};

// parse string amount of token to bignumber based on decimal, ex: 1WEGLD return 1*10**18 in bignumber format
export const parseTokenDecimals = (ticker: string, amount: any) => {
  switch (ticker) {
    case tickers.WEGLD.value:
      return parseUnits(
        parseFloat(amount).toFixed(4).toString(),
        tickers.WEGLD.decimals,
      );
    case tickers.USDC.value:
      return parseUnits(
        parseFloat(amount).toFixed(4).toString(),
        tickers.USDC.decimals,
      );
    case tickers.RIDE.value:
      return parseUnits(
        parseFloat(amount).toFixed(4).toString(),
        tickers.RIDE.decimals,
      );
    case tickers.MEX.value:
      return parseUnits(
        parseFloat(amount).toFixed(4).toString(),
        tickers.MEX.decimals,
      );
    default:
      return parseUnits("0");
  }
};

// convert ascii to hex
export const asciiToHex = (str: any) => {
  const arr1 = [];
  for (let n = 0, l = str.length; n < l; n++) {
    const hex = Number(str.charCodeAt(n)).toString(16);
    arr1.push(hex);
  }
  return arr1.join("");
};

// convert hex to ascii
export const hexToAscii = (str1: any) => {
  const hex = str1.toString();
  let str = "";
  for (let n = 0; n < hex.length; n += 2) {
    str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
  }
  return str;
};

// get pair info from graphql
export const getPairInfo = async (fromTicker: string, toTicker: string) => {
  try {
    const res = await axios.post(networkConfig.graphqlAddress, {
      variables: {
        fromTicker,
        toTicker,
      },
      query:
        "query ($fromTicker: String!, $toTicker: String!) {\r\n  fromTicker: getTokenPriceUSD(tokenID: $fromTicker)\r\n  toTicker: getTokenPriceUSD(tokenID: $toTicker)\r\n \tpairs(offset: 0, limit: 100) {\r\n    address\r\n    firstToken {\r\n      name\r\n      identifier\r\n    }\r\n    secondToken {\r\n      name\r\n      identifier\r\n    }\r\n    firstTokenPrice\r\n    firstTokenPriceUSD\r\n    secondTokenPrice\r\n    secondTokenPriceUSD\r\n    info {\r\n      reserves0\r\n      reserves1\r\n      totalSupply\r\n    }\r\n    state\r\n  }\r\n}\r\n",
    });
    // res.data.data.pairs.forEach((pair: any) => {
    //   if (
    //     pair.firstToken.identifier === fromTicker ||
    //     pair.firstToken.identifier === toTicker
    //   )
    //     console.log("firstTokenPrice: " + pair.firstTokenPriceUSD);
    //   if (
    //     pair.secondToken.identifier === fromTicker ||
    //     pair.secondToken.identifier === toTicker
    //   )
    //     console.log("secondTokenPrice: " + pair.secondTokenPriceUSD);
    // });
    // console.log(res.data.data);
    return (
      "1 " +
      getTickerNameFromTickerIdentifier(toTicker) +
      " ≃ " +
      (res.data.data.toTicker / res.data.data.fromTicker).toFixed(4) +
      " " +
      getTickerNameFromTickerIdentifier(fromTicker)
    );
    // console.log(
    //   parseFloat(res.data.data.toTicker) / parseFloat(res.data.data.fromTicker),
    // );
  } catch (error) {
    return "";
    console.log(error);
  }
};

// get a token balance of address
export const getESDTBalance = async (address: string, ticker?: string) => {
  if (address) {
    if (ticker) {
      const decimals = getTickerDecimals(ticker);
      try {
        const res = await axios.get(
          `${networkConfig.apiAddress}/accounts/${address}/tokens?identifier=${ticker}`,
        );
        // console.log(res?.data);
        // old code
        // if (res?.data?.balance) {
        //   return ethers.utils.formatUnits(res?.data?.balance, decimals).toString();
        // } else {
        //   return 0;
        // }
        //new code
        if (res?.data[0]?.balance) {
          return ethers.utils
            .formatUnits(res?.data[0]?.balance, decimals)
            .toString();
        } else {
          return 0;
        }
      } catch (error) {
        console.log(error);
      }

      return 0;
    } else {
      try {
        const res = await axios.get(
          `${networkConfig.apiAddress}/accounts/${address}/tokens`,
        );

        const data = res?.data.map((token: any) => {
          return {
            ticker: token.ticker,
            label: token.name,
            balance: parseFloat(
              ethers.utils
                .formatUnits(token.balance, token.decimals)
                .toString(),
            ).toFixed(2),
          };
        });
        // console.log(data);
        // const decimals = getTickerDecimals(ticker);
        // console.log(res?.data);
        // old code
        // if (res?.data?.balance) {
        //   return ethers.utils.formatUnits(res?.data?.balance, decimals).toString();
        // } else {
        //   return 0;
        // }
        //new code
        // if (res?.data[0]?.balance) {
        //   return ethers.utils
        //     .formatUnits(res?.data[0]?.balance, decimals)
        //     .toString();
        // } else {
        return data;
        // }
      } catch (error) {
        console.log(error);
      }

      return 0;
    }
  }
  return 0;
};

// calculate minimal amount out of token swap
export const getAmountOut = async (
  amountIn: any,
  tickerIn: string,
  tickerOut: string,
) => {
  try {
    const res = await axios.post(networkConfig.graphqlAddress, {
      variables: {
        amount: amountIn,
        tokenInID: tickerIn,
        pairAddress: getPairAddress(tickerIn, tickerOut),
      },
      query:
        "query ($amount: String\u0021, $tokenInID: String\u0021, $pairAddress: String\u0021) {\n  getAmountOut(amount: $amount, tokenInID: $tokenInID, pairAddress: $pairAddress)\n}\n",
    });

    // console.log(res?.data?.data?.getAmountOut);
    // console.log(calculateTokenDecimals(tickerOut, res?.data?.data?.getAmountOut));
    return calculateTokenDecimals(tickerOut, res?.data?.data?.getAmountOut);
    //return res;
  } catch (error) {
    console.log(error);
    return 0;
  }
};

// get pair address based on two token
export const getPairAddress = (tickerIn: string, tickerOut: string) => {
  let pair = "";
  switch (tickerIn) {
    case tickers.USDC.value:
      pair = pairAddress.WEGLD_USDC;
      break;
    case tickers.RIDE.value:
      pair = pairAddress.WEGLD_RIDE;
      break;
    case tickers.MEX.value:
      pair = pairAddress.WEGLD_MEX;
      break;
    default:
      break;
  }
  switch (tickerOut) {
    case tickers.USDC.value:
      pair = pairAddress.WEGLD_USDC;
      break;
    case tickers.RIDE.value:
      pair = pairAddress.WEGLD_RIDE;
      break;
    case tickers.MEX.value:
      pair = pairAddress.WEGLD_MEX;
      break;
    default:
      break;
  }
  return pair;
};

// convert bignumber to hex string padded (remove 0x)
export const bnToHexStringPadded = (bn: BigNumber) => {
  return bn.toHexString().slice(2);
};

export const getTickerNameFromTickerIdentifier = (ticker: string) => {
  const truncateRegex = /^([A-Z])\w+/;
  const match = ticker.match(truncateRegex);
  if (!match) return ticker;
  return `${match[0]}`;
};

export const getErdGasPriceModifier = async () => {
  try {
    const res = await axios.get(networkConfig.configAddress);
    return res?.data?.data?.config?.erd_gas_price_modifier;
  } catch (error) {
    return 0;
  }
};
