import * as Dapp from "@elrondnetwork/dapp";

export const dAppName = "Dapp";
export const decimals = 2;
export const denomination = 18;
// export const gasPrice = 50000000000;
export const version = 1;
// export const gasLimit = 100000000;
export const gasPerDataByte = 1500;

export const walletConnectBridge = "https://bridge.walletconnect.org";
export const walletConnectDeepLink =
  "https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet.dev&link=https://maiar.com/";

export const contractAddress =
  "erd1qqqqqqqqqqqqqpgquvt728n40ssd8n2qns9jrlqpwq2jc4rj4cysfuj3ad";

export const wrapContractAddress =
  "erd1qqqqqqqqqqqqqpgqhe8t5jewej70zupmh44jurgn29psua5l2jps3ntjj3";

export const network: Dapp.NetworkType = {
  id: "mainnet",
  name: "Mainnet",
  egldLabel: "EGLD",
  walletAddress: "https://wallet.elrond.com/",
  apiAddress: "https://api.elrond.com",
  gatewayAddress: "https://gateway.elrond.com",
  explorerAddress: "http://explorer.elrond.com/",
};

export const networkConfig = {
  id: "mainnet",
  name: "Mainnet",
  egldLabel: "EGLD",
  walletAddress: "https://wallet.elrond.com/",
  apiAddress: "https://api.elrond.com",
  gatewayAddress: "https://gateway.elrond.com",
  explorerAddress: "http://explorer.elrond.com/",
  graphqlAddress: "https://graph.maiar.exchange/graphql",
  configAddress: "https://gateway.elrond.com/network/config",
};

export const tickers = {
  USDC: { value: "USDC-c76f1f", label: "USDC", decimals: 6 },
  WEGLD: { value: "WEGLD-bd4d79", label: "WEGLD", decimals: 18 },
  RIDE: { value: "RIDE-ae50f0", label: "RIDE", decimals: 18 },
  MEX: { value: "MEX-455c57", label: "MEX", decimals: 18 },
  ITHEUM: { value: "ITHEUM-df6f26", label: "ITHEUM", decimals: 18 },
};

export const pairAddress = {
  WEGLD_USDC: "erd1qqqqqqqqqqqqqpgqeel2kumf0r8ffyhth7pqdujjat9nx0862jpsg2pqaq",
  WEGLD_RIDE: "erd1qqqqqqqqqqqqqpgqav09xenkuqsdyeyy5evqyhuusvu4gl3t2jpss57g8x",
  WEGLD_MEX: "erd1qqqqqqqqqqqqqpgqa0fsfshnff4n76jhcye6k7uvd7qacsq42jpsp6shh2",
  WEGLD_ITHEUM:
    "erd1qqqqqqqqqqqqqpgqpmud7t8uprrxzgu8eq2mtkl08kesflj62jps9j8dyh",
};
